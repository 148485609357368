const HomePageSections = {
  ABOUT: {
    name: "about",
  },
  TEAM: {
    name: "our_team",
  },
  GET_INVOLVED: {
    name: "get-involved",
  },
  SPEAKERS: {
    name: "speakers",
  },
  LEADERSHIP: {
    name: "leadership",
  },
  SPONSORS: {
    name: "sponsors",
  },
};

export default HomePageSections;
