export const LargeLogoSponsors = [
  {
    name: "ucla-cs",
    src: require("static/sponsors/ucla-cs.png"),
    website: "https://www.cs.ucla.edu/",
  },
  {
    name: "balsamiq",
    src: require("static/sponsors/balsamiq.png"),
    website: "https://balsamiq.com/",
  },
];

export const SmallLogoSponsors = [
  {
    name: "ucla_samueli_engineering",
    src: require("static/sponsors/ucla-samueli-engineering.png"),
    website: "https://samueli.ucla.edu/",
  },
  {
    name: "yubico",
    src: require("static/sponsors/yubico.png"),
    website: "https://www.yubico.com/",
  },
  {
    name: "northrop-grumman",
    src: require("static/sponsors/northrop-grumman.png"),
    website: "https://www.northropgrumman.com/",
  },
  {
    name: "disney",
    src: require("static/sponsors/disney.png"),
    website: "https://thewaltdisneycompany.com/",
  },
  {
    name: "capcom",
    src: require("static/sponsors/capcom.svg"),
    website: "https://www.capcom.com/us/",
  },
  {
    name: "cloud-girls",
    src: require("static/sponsors/cloud-girls.png"),
    website: "https://cloudgirls.org/",
  },
];
