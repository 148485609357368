const CaourselImages = [
  {
    src: require("static/images/demo-microsoft-vr.jpg"),
    alt: "VR Demo",
  },
  {
    src: require("static/images/workshop-dev-tools.jpg"),
    alt: "Dev Tools Workshop",
  },
  {
    src: require("static/images/workshop-kaylie.jpg"),
    alt: "Workshop Speaker",
  },
  {
    src: require("static/images/workshop-scratch.jpg"),
    alt: "Scratch Workshop",
  },
  {
    src: require("static/images/workshop-audience.jpg"),
    alt: "Workshop Audience",
  },
];

export default CaourselImages;
